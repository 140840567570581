<template>
	<div class="pricing5 py-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 text-center">
					<h3 class="mb-3">Selecciona el Plan que mejor te ajuste</h3>
					<!-- <h3 class="font-weight-normal">Sólo la práctica hace al maestro</h3> -->
					<div class="mt-5 mx-auto">
						<div class="d-flex justify-content-center">
							<div class="pill-left">
								<label
									for="option1"
									class="rounded-left label"
									:class="picked === 'Monthly' ? 'active' : ''"
								>
									<input
										type="radio"
										id="option1"
										value="Monthly"
										v-model="picked"
										class="d-inline m-0 p-0"
									/>
									Mensual
								</label>
							</div>
							<div class="pill-right">
								<label
									for="option2"
									class="rounded-right label"
									:class="picked === 'Yearly' ? 'active' : ''"
								>
									<input
										type="radio"
										id="option2"
										value="Yearly"
										v-model="picked"
										class="d-inline m-0 p-0"
									/>
									Anual
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row text-center pricing-box">
				<div class="col-md-4">
					<div
						class="shadow-lg card card-shadow border-0 mb-4"
						style="background-image: url(/p5-img1.jpg); background-repeat: no-repeat"
					>
						<div class="card-body">
							<h6 class="text-white mt-3 mb-0">BASIC PACK</h6>
							<h2 class="text-white mt-0" v-if="picked === 'Monthly'">$100</h2>
							<h2 class="text-white mt-0" v-else>$1000</h2>
							<ul class="general-listing">
								<li class="py-2 d-block">
									<h5 class="font-weight-normal"></h5>
									<!-- <h6 class="subtitle font-weight-normal">Premium images / Day</h6> -->
								</li>
								<li class="py-3 d-block">
									<h5 class="font-weight-normal"></h5>
									<!-- <h6 class="subtitle font-weight-normal">Video Footage / Day</h6> -->
								</li>
								<li class="py-3 d-block">
									<h5 class="font-weight-normal">Tiempo máximo: 1 hora por partido</h5>
									<!-- <h6 class="subtitle font-weight-normal">Can access the site</h6> -->
								</li>
							</ul>
							<a class="btn btn-outline-success btn-md rounded-pill mb-3" href="#f1"
								><span>BUY BASIC</span></a
							>
						</div>
					</div>
				</div>
				<div class="col-md-4 middle-box">
					<div
						class="shadow-lg card card-shadow border-0 mb-4"
						style="background-image: url(/p5-img2.jpg); background-repeat: no-repeat"
					>
						<div class="card-body">
							<h6 class="text-white mt-3 mb-0">BASIC ADVANCED PACK</h6>
							<h2 class="text-white mt-0" v-if="picked === 'Monthly'">$200</h2>
							<h2 class="text-white mt-0" v-else>$2000</h2>
							<div class="star-plan mt-3">
								<img src="/star.png" alt="wrapkit" />
							</div>
							<ul class="general-listing">
								<li class="py-2 d-block">
									<h5 class="font-weight-normal"></h5>
									<!-- <h6 class="subtitle font-weight-normal">Premium images / Day</h6> -->
								</li>
								<li class="py-3 d-block">
									<h5 class="font-weight-normal"></h5>
									<!-- <h6 class="subtitle font-weight-normal">Video Footage / Day</h6> -->
								</li>
								<li class="py-3 d-block">
									<h5 class="font-weight-normal">Tiempo máximo: 2 horas por partido</h5>
									<!-- <h6 class="subtitle font-weight-normal">Can access the site</h6> -->
								</li>
							</ul>
							<a
								class="btn btn-success-gradiant btn-md text-white rounded-pill mb-3"
								href="#f1"
								><span>BUY BASIC ADVANCED</span></a
							>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div
						class="shadow-lg card card-shadow border-0 mb-4"
						style="background-image: url(/p5-img3.jpg); background-repeat: no-repeat"
					>
						<div class="card-body">
							<h6 class="text-white mt-3 mb-0">PRO PACK</h6>
							<h2 class="text-white mt-0" v-if="picked === 'Monthly'">$300</h2>
							<h2 class="text-white mt-0" v-else>$3000</h2>
							<ul class="general-listing">
								<!-- <li class="d-block py-3">
									<h5 class="font-weight-normal">50 IMAGES</h5>
									<h6 class="subtitle font-weight-normal">Premium images / Day</h6>
								</li> -->
								<li class="d-block py-3">
									<h5 class="font-weight-normal"></h5>
									<!-- <h6 class="subtitle font-weight-normal">Video Footage / Day</h6> -->
								</li>
								<li class="d-block py-3">
									<h5 class="font-weight-normal">
										Máximo tres partidos/ Pelotas estándar / Tiempo máximo: 2 horas por
										partido
									</h5>
									<!-- <h6 class="subtitle font-weight-normal">Can access the site</h6> -->
								</li>
							</ul>
							<a class="btn btn-outline-success btn-md rounded-pill mb-3" href="#f1"
								><span>BUY PRO</span></a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pricing-page',
		components: {},
		data() {
			return {
				picked: 'Monthly',
				plans: [
					{
						name: 'Basic',
						id: 1,
						games: 10,
						tpTubeDto: {
							id: 1,
							name: 'Botes Basic',
						},
						features: '1',
					},
					{
						name: 'Basic Advance',
						id: 2,
						games: 10,
						tpTubeDto: {
							id: 1,
							name: 'Botes Basic',
						},
						features: 'Tiempo máximo: 2 horas por partido',
					},
					{
						name: 'Pro Pack',
						id: 3,
						games: 10,
						tpTubeDto: {
							id: 1,
							name: 'Botes Basic',
						},
						features:
							'Máximo tres partidos/ Pelotas estándar / Tiempo máximo: 2 horas por partido',
					},
				],
			};
		},
		mounted() {
			SwalMixin('Planes en contrucción. Pruebe más tarde.', 'warning')
			this.$router.push('listadoTickets');
		},
	};
</script>

<style scoped>
	@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);

	.pill-left,
	.pill-right {
		display: flex;
		align-content: center;
	}
	.pill-left label,
	.pill-right label {
		width: 160px !important;
		height: 60px;
	}

	.card {
		transition: all 0.3s ease-in;
	}
	.card:hover {
		transform: scale(1.08);
	}
	.pricing5 {
		font-family: 'Montserrat', sans-serif;
		color: #8d97ad;
		font-weight: 300;
	}

	.pricing5 h1,
	.pricing5 h2,
	.pricing5 h3,
	.pricing5 h4,
	.pricing5 h5,
	.pricing5 h6 {
		color: #3e4555;
	}
	.pricing5 h2 {
		margin-top: 5px !important;
	}
	.pricing5 h6 {
		font-size: 18px;
	}
	ul {
		padding-left: 0;
	}
	.label {
		border: 1px solid #6c757d !important;
		font-weight: normal;
		color: #6c757d !important;
		display: inline;
		margin: 0;
		padding: 0;
	}
	.active {
		background-color: #6c757d !important;
		color: white !important;
	}
	.pricing5 h5 {
		line-height: 22px;
		font-size: 18px;
	}

	.pricing5 .font-weight-medium {
		font-weight: 500;
	}

	.pricing5 .bg-light {
		background-color: #f4f8fa !important;
	}

	.pricing5 .subtitle {
		color: #8d97ad;
		line-height: 24px;
	}

	.pricing5 .card.card-shadow {
		-webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
		box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
	}

	.pricing5 .general-listing {
		margin: 90px 0 60px 0;
	}

	.pricing5 .card {
		background-size: 100% !important;
	}

	.pricing5 .yearly {
		display: none;
	}

	.pricing5 .pricing-box {
		margin-top: 120px;
	}

	.pricing5 .pricing-box .middle-box {
		margin-top: -75px;
	}

	.pricing5 .pricing-box .middle-box .general-listing {
		margin-top: 60px;
	}

	@media (max-width: 767px) {
		.pricing5 .pricing-box {
			margin-top: 40px;
		}
		.pricing5 .pricing-box .middle-box {
			margin-top: 0px;
		}
	}

	.pricing5 .btn-success-gradiant {
		background: #2cdd9b;
		background: -webkit-linear-gradient(
			legacy-direction(to right),
			#2cdd9b 0%,
			#1dc8cc 100%
		);
		background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc));
		background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
		background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
		background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
	}

	.pricing5 .btn-success-gradiant:hover {
		background: #1dc8cc;
		background: -webkit-linear-gradient(
			legacy-direction(to right),
			#1dc8cc 0%,
			#2cdd9b 100%
		);
		background: -webkit-gradient(linear, left top, right top, from(#1dc8cc), to(#2cdd9b));
		background: -webkit-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
		background: -o-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
		background: linear-gradient(to right, #1dc8cc 0%, #2cdd9b 100%);
	}

	.pricing5 .btn-md {
		padding: 15px 45px;
		font-size: 16px;
	}

	.pricing5 .rounded-left {
		border-top-left-radius: 60px !important;
		border-bottom-left-radius: 60px !important;
	}

	.pricing5 .rounded-right {
		border-top-right-radius: 60px !important;
		border-bottom-right-radius: 60px !important;
	}

	.pricing5 .btn-outline-success {
		color: #2cdd9b;
		background-color: transparent;
		border-color: #2cdd9b;
	}

	.pricing5 .btn-outline-success:hover {
		background: #2cdd9b;
		border-color: #2cdd9b;
		color: #ffffff;
	}
</style>
