<template>
  <h3>Cerrando sesión...</h3>
</template>
<script>
  import { mapActions } from 'vuex'
  export default {
    mounted() {
        this.logout();
    },
    methods: {
      logout() {
        localStorage.removeItem('authUser');
        this.$router.push('/login');
      }
    },
  }
</script>
<style>
</style>