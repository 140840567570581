<template>
	<div class="form-body custom-login container-fluid p-0 login-container">
		<b-alert v-if="showError" show variant="danger" id="alertError"
			><a href="#" class="alert-link">{{ msgError }}</a></b-alert
		>
		<div class="row">
			<div class="col-md-6 col-sm-12 p-0 image-column">
				<img src="../../../public/imagen-login.jpg" alt="" class="background-image"/>
			</div>
			<div class="col-xl-6 col-lg-12 p-0 user-login form-column">
				<div class="form-content">
					<div class="form-items">
						<div v-if="!olvido">
							<form>
								<label for="username">Usuario</label>
								<input
									v-model="user"
									class="inputLogin"
									id="user"
									type="text"
									name="username"
									placeholder="Usuario"
									required
								/>
								<label for="pass">Contraseña</label>
								<input
									v-model="pass"
									class="inputLogin"
									type="password"
									name="pass"
									placeholder="Contraseña"
									required
								/>
							</form>
						</div>
						<div v-else>
							<form>
								<label for="username">Correo electrónico</label>
								<input
									v-model="user"
									id="user"
									type="text"
									name="username"
									placeholder="Correo electrónico"
									required
								/>
							</form>
						</div>
						<div v-if="!olvido" class="login-button__container w-100 mt-3">
							<button @click.prevent="solicitaLogin" class="btn primary">Acceder</button>
							<!-- <button
								@click.prevent="solicitaRegistro"
								class="btn primary mt-3"
								style="opacity: 0.4"
							>
								Registrarse
							</button> -->
						</div>
						<div v-else class="login-button__container w-100 mt-3">
							<button @click.prevent="" class="btn primary">Enviar solicitud</button>
							<button
								@click.prevent="olvido = false"
								class="btn primary mt-3"
								style="opacity: 0.4"
							>
								Volver
							</button>
						</div>
						<div v-if="!olvido" class="d-flex w-100 mt-3">
							<div class="w-50 d-flex align-items-center checkbox-wrapper">
								<input
									v-model="recordar"
									class="styled-checkbox checks2"
									id="styled-checkbox-1"
									type="checkbox"
									value="value1"
								/>
								<label for="styled-checkbox-1">Recordar contraseña</label>
							</div>
							<div class="w-50 passremind__wrapper">
								<a href="#" @click="olvido = true">¿Has olvidado tu contraseña?</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api/services';
	import { mapActions } from 'vuex';
	export default {
		data() {
			return {
				user: null,
				pass: null,
				olvido: false,
				showError: false,
				msgError: '',
				recordar: false,
			};
		},
		created() {
			const storedPassword = localStorage.getItem('password');
			if (storedPassword) {
				this.user = localStorage.getItem('usuario');
				this.pass = storedPassword;
				this.recordar = true;
			}
		},
		methods: {
			...mapActions({
				setAuthUser: 'setAuthUser',
			}),
			solicitaRegistro() {
				this.$router.push({ name: 'Register' });
			},

			async solicitaLogin() {
				var details = {
					username: this.user,
					password: this.pass,
				};
				try {
					const res = await api.login(details);
					if (res === null || (res !== null && res.token === undefined)) 
					{	
						//console.log("error de autenticacion")
						this.msgError = 'Usuario no autorizado. Verificar por favor...'
						/* if (res !== null && res.message !== null && res.message === '401 UNAUTHORIZED') {
							this.msgError = 'Usuario y password son incorrectos. Verificar por favor...'
						} */
						this.showError = true;
						const ambitoThis = this;
						setTimeout(() => {
							var slideSource = document.getElementById('alertError');
							slideSource.classList.toggle('fade');
							ambitoThis.showError = false;
						}, '3000');
					} 
					else {
						this.setAuthUser(res);
						//localStorage.setItem('usuario', JSON.stringify(this.user));
						localStorage.setItem('authUser', JSON.stringify(res))
						localStorage.setItem('usuario', this.user);
						if (this.recordar) {
							localStorage.setItem('password', this.pass);
						}  
						//this.$router.push({ name: 'Dashboard' });
						this.$router.push({ name: 'listadoTickets' });
					}
				}
				catch (error) {
					//console.log("error", error);
				}	
			},
		},
	};
</script>
<style>
	.mb5x {
		margin-bottom: 5px !important;
	}

	.alert {
		position: fixed;
		transition: opacity 1s;
		top: 0px;
		left: 0px;
		width: 100%;
		z-index: 9999;
		border-radius: 0px;
	}

	.alert.fade {
		opacity: 0;
	}

	.f23x {
		font-size: 23px;
	}

	.inputLogin {
		border: none;
		background-color: #f6f6f6 !important;
	}

	.login-container {
		display: flex;
		height: 100vh; /* Ajusta la altura según tus necesidades */
	}

	.image-column {
		flex: 1;
	}

	.form-column {
		flex: 1;
	}

	.background-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>
